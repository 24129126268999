/* Normal desktop :1200px. */

@media (min-width: 1200px) and (max-width: 1500px) {
  .team_text_inner {
    padding: 20px;
  }
}

@media (min-width:992px) {
  .nav-link::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #007bff;
    transition: width 0.3s ease;
  }
}
/* Normal desktop :992px. */

@media (min-width: 992px) and (max-width: 1199px) {
  
  .card-body p{
    line-height: 19px;
    
  }
  .team_name{
    font-size:18px;
  }
  .category{
    padding-bottom: 7px;
padding-top:9px;
font-size:11px;  }
.team_text h4{
  padding-top:2rem ;
} 
  .text_overlay h1 {
    font-size: 3rem;
  }
  .text_overlay p {
    font-size: 22px;
  }
  .team_text_inner {
    padding: 10px;
  }
  .service_inner_text {
    font-size: 15px;
  }
  .counter_desc span {
    font-size: 14px;
  }
  .video_thumb img {
    margin-bottom: 240px;
  }
  .team_slide {
    width: 40%;
    margin-bottom: 30px;
  }

  .col span {
    font-size: 15px;
  }
  .contact-us .container {
    padding: 0px;
  }
  .techli {
    width: 100%;
    text-align: center;
    padding: 7px !important;
  }
  .single_testimonial_content_title h4 {
    bottom: 40px;
    position: absolute;
  }
  .single_testimonial_content_title span {
    bottom: 12px;
    position: absolute;
  }
  .card-title {
    font-size: 22px !important;
  }
  .servicelist span {
    font-size: 15px;
  }
  .service_image {
    width: 85px;
  }
  .service_inner_in_text {
    font-size: 20px;
  }
  .circle1 {
    left: 54px;
  }
  .scrolled button.getQuote {
    margin: 0px 0px -11px 0px;
    padding: 5px 10px 5px 10px;
  }
  .circle2 {
    left: 80%;
  }
  .single_service img {
    width: 20px;
    height: 20px;
  }
  .it_solution .container .row {
    padding-bottom: 25px;
  }
  .single_service_content h4 {
    font-size: 15px;
    margin: 3px 5px 30px 5px;
  }

  .technology .nav-tabs .nav-link {
    padding: 10px !important;
  }
  .technology .tech-list li {
    margin: 15px;
  }
  a.dtbtn {
    font-size: 15px !important;
    padding: 9px 25px !important;
  }
  .single_contact_now_icon img {
    width: 66%;
    padding: 18px;
  }
  .testimonial_list .slick-slide div {
    min-height: 300px !important;
    max-height: 300px;
  }
  header .navbar .navbar-nav a.nav-link,
  header .navbar .navbar-nav a.nav-link.active {
    color: white;
    font-weight: 700;
    padding-left: 0.5rem;
    padding: right 0.5rem;
    font-size: 12px;
  }
  .single_inbox_text p {
    font-size: 15px !important;
  }
  .single_inbox_text img {
    margin: 0 auto 10px;
  }
  .single_inbox_text h3 {
    font-size: 22px;
  }
  .getQuote {
    top: 7px;
    padding: 2px 10px 2px 10px;
    font-size: 11px;
  }
  .about_thumb {
    position: relative;
    width: 100%;
  }
  .techno_menu > ul > li > a {
    font-size: 16px !important;
    margin: 30px 6px !important;
  }
  .owl-prev,
  .owl-next {
    display: none;
  }
  .top_feature .techno_flipbox .techno_flipbox_inner {
    padding: 0 20px !important;
  }
  .section_main_title h1,
  .section_main_title h2 {
    font-size: 35px;
  }
  .recent-post-text h6 {
    font-size: 16px;
    margin: 0;
  }
  .recent-post-text span {
    font-size: 13px;
  }
  .recent-post-image {
    margin-right: 10px !important;
  }
  .em-nivo-slider-wrapper:hover .nivo-nextNav {
    right: -30px !important;
  }
  .em-nivo-slider-wrapper .nivo-directionNav .nivo-prevNav {
    margin-left: -50px !important;
  }
  .service_style_two_title h4 {
    font-size: 22px;
  }
  .nav-tabs .nav-link {
    padding: 22px 35px !important;
  }
  .service_style_three_title h4 {
    font-size: 21px;
  }
  .nagative_margin3 {
    margin-top: 0 !important;
    padding-top: 70px;
  }
  .feature_style_three:hover .feature_style_tree_content {
    bottom: 8px !important;
  }
  .feature_style_three_title h4 {
    font-size: 18px;
  }
  .techno_flipbox .techno_flipbox_font,
  .techno_flipbox .techno_flipbox_back {
    min-height: 280px !important;
  }
  .choose_us .techno_flipbox .techno_flipbox_font,
  .choose_us .techno_flipbox .techno_flipbox_back {
    min-height: 400px !important;
  }
  .it_work_style_two .techno_flipbox .techno_flipbox_font,
  .it_work_style_two .techno_flipbox .techno_flipbox_back {
    min-height: 410px !important;
  }
  .appointment .slider_text_inner h1 {
    font-size: 50px !important;
  }
  .heading_experience_title h2 {
    font-size: 26px !important;
  }
  .heading_experience_text p {
    font-size: 16px;
  }
  .headingh_experience_number h1 {
    font-size: 118px !important;
  }
  #section-quote .container-pe-quote .li-quote-10 {
    right: 130px !important;
  }
  #section-quote .container-pe-quote .li-quote-2 {
    left: 30px !important;
  }
  .team_style_three_title h4 {
    font-size: 17px !important;
  }
  .banner_text_content h1 {
    font-size: 55px !important;
  }
  .banner_area .data_science_video_inner {
    left: 50% !important;
  }
  .banner2 .banner_text_content h1 {
    font-size: 40px !important;
  }
  .banner3 .banner_text_content h1 {
    font-size: 45px !important;
  }
  .about_icon_box_inner span {
    font-size: 15px !important;
  }
  .about_icon_box_inner i {
    height: 26px !important;
    width: 26px !important;
    line-height: 26px !important;
    font-size: 14px !important;
    margin-right: 5px !important;
  }
  .service_style_eleven_title h4 {
    font-size: 18px !important;
  }
  .service_style_eleven_text p {
    font-size: 14px;
  }
  .single_software_box_content h5 {
    font-size: 15px !important;
  }
  .single_service_style_eleven {
    padding: 35px 18px 30px !important;
  }
  .feature_style_eight {
    padding: 60px 26px 45px !important;
  }
  .service_style_nine_content h4 {
    font-size: 21px;
  }
  .testimonial_style_three_content {
    padding: 23px 28px 30px 10px !important;
  }
  .testimonial_style_three_content::before {
    left: -130px !important;
    width: 169% !important;
  }
  .testimonial_style_three_text p {
    font-size: 18px !important;
  }
  .pricing_style_four_body {
    padding: 55px 21px 40px !important;
  }
  .pricing_style_four_body ul li {
    font-size: 17px !important;
  }
  .service_style_ten_content h4 a {
    font-size: 20px !important;
  }
  .single_process_thumb {
    height: 200px !important;
    width: 200px !important;
    line-height: 200px !important;
  }
  .counter_style_four_text h5 {
    font-size: 17px !important;
  }
}
.feature_style_five_title h4 {
  font-size: 19px;
}

/* Tablet desktop :768px. */

@media (min-width: 768px) and (max-width: 991px) {
  .right_button{
    justify-content: normal !important;
    padding-top:15px !important;
  }
  .slider_text_inner h1{
    font-size: 45px !important;
  }
  .text_overlay h1 {
    font-size: 3rem;
  }
  .imag{
    height:230px !important;
  }
  .card .imag img{
    height: 220px !important;
  }
  .techli {
    font-size:9px !important;
  }
  .card{
    min-height:415px !important;
  }
  .card-body p{
    font-size:12px !important;
    line-height: 18px;
    height: 60px !important;
  }
  .text_overlay p {
    font-size: 17px;
  }
  .service_inner_text {
    font-size: 16px;
  }
  .service_inner_in_text {
    font-size: 20px;
  }
  .header_top_menu {
    display: none;
  }
  .col span {
    font-size: 15px;
  }
  .it_care_text_inner {
    top: 0%;
  }
  .it_care_text_inner h1 {
    font-size: 27px;
  }
  .it_care_bt {
    font-size: 12px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .choose_us{
    padding: 55px ;
  }
  .companies_list_text h1{
    font-size:35px;
  }
  .circle1 {
    left: 35px;
  }
  .circle2 {
    top: 49px;
    left: 70%;
  }
  .each_process {
    padding: 20px;
  }
  .single_service_inner_thumb img {
    display: none;
  }
  .counter_area .row .col {
    padding: 20px;
  }
  .feature_style_five_title h4 {
    font-size: 16px;
  }
  .inbox_text {
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
  }
  .single_testimonial_content_title h4 {
    bottom: 35px;
    font-size: 20px;
    position: absolute;
  }
  .lines:first-child,
  .lines:nth-child(3),
  .lines:nth-child(2),
  .lines:nth-child(4),
  .lines:nth-child(5),
  .lines:nth-child(6) {
    border-left: 0 !important;
    border-bottom: 0 !important;
  }
  .contact-us .container {
    padding: 0;
  }
  .social-icon-footer li{
    padding-right: 12px !important;
    padding-left: 0px !important;
  }
  .lines {
    flex: auto;
    border-left: 1px solid;
    border-bottom: 1px solid;
    padding-bottom: 20px;
    padding-top: 20px;
    border-color: rgba(0, 0, 0, 0.2);
  }
  .process_text h1 {
    font-size: 30px;
  }
  .section_main_title span {
    font-size: 18px;
  }
  .service_area {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }
  .process_text h6 {
    font-size: 16px;
  }
  .it_solution .container .row {
    padding: 12px;
  }
  .it_care_bt {
    color: white;
  }
  .team_text_inner {
    padding: 20px;
  }
  .video_thumb img {
    width: 50%;
  }
 
  .heading_experience_text p{
    font-size:16px !important;
  }
  .heading_experience_text{
    padding-top:30px;
  }
  .section_main_title span {
    font-size: 19px !important;
  }
  .right_button button {
    padding: 10px 20px 10px 20px;
    font-size: 15px;
  }
  .text_choose_us {
    padding: 15px;
  }
  .team_slide {
    padding-bottom: 20px;
  }
  .single_testimonial_content_title span {
    bottom: 12px;
    position: absolute;
  }
  .widget-title {
    top: -30px;
  }
  .technology .tech-list li {
    margin: 11px;
  }
  .technology .tech-list li img {
    max-width: 125px;
  }
  .testimonial_list .slick-slide div {
    max-height: 285px;
    min-height: 285px;
  }
  .about_thumb {
    position: relative;
    width: 100%;
  }

  .owl-prev,
  .owl-next {
    display: none;
  }
  .slider_text_inner h1 {
    font-size: 50px !important;
  }
  .about_area .section_main_title h1,
  .section_main_title h2 {
    font-size: 26px;
  }
  .team_area .button.two {
    display: none;
  }
  .single_it_work_content_list::before {
    display: none;
  }
  .call_do_action .section_title {
    text-align: center;
  }
  .call_do_action .single-video {
    text-align: center;
    margin-top: 30px !important;
  }
  .counter_area .nagative_margin {
    margin-top: 0 !important;
  }
  .counter_area {
    padding-top: 100px;
  }
  .blog_area .button.two {
    display: none !important;
  }
  .recent-post-item {
    margin-top: 6px !important;
  }
  .em-nivo-slider-wrapper:hover .nivo-nextNav {
    right: -30px !important;
  }
  .em-nivo-slider-wrapper .nivo-directionNav .nivo-prevNav {
    margin-left: -50px !important;
  }
  .em-slider-sub-title {
    display: none;
  }
  .text-center .em-slider-descript {
    display: none;
  }
  .em-nivo-slider-wrapper .nivo-directionNav a {
    height: 58px !important;
    width: 33px !important;
    line-height: 54px !important;
    font-size: 30px !important;
  }
  .em-button-button-area a {
    margin-top: 0 !important;
  }
  .single_slider_shape_image img {
    display: none;
  }
  .single_slider_rot_inner {
    display: none;
  }
  .video_shape {
    display: none;
  }
  .main_video {
    margin-top: -115px !important;
  }
  .nav-tabs .nav-link {
    padding: 13px 22px !important;
    font-size: 16px !important;
  }
  .nagative_margin2 {
    margin-top: -90px !important;
  }
  .single_slider_shape_image {
    display: none;
  }
  #section-quote .container-pe-quote.right {
    display: none;
  }
  #section-quote .container-pe-quote.left {
    display: none;
  }
  .testimonial-bg {
    padding-bottom: 0 !important;
  }
  .nagative_margin3 {
    margin-top: 0 !important;
    padding-top: 70px;
  }
  .feature_style_three:hover .feature_style_tree_content {
    bottom: 15px !important;
  }
  .feature_style_three_title h4 {
    font-size: 20px;
  }
  .section_button {
    display: none;
  }
  .em-slider-main-title {
    font-size: 90px !important;
  }
  .techno_flipbox .techno_flipbox_font,
  .techno_flipbox .techno_flipbox_back {
    min-height: 280px !important;
  }
  .it_work_style_two .techno_flipbox .techno_flipbox_font,
  .it_work_style_two .techno_flipbox .techno_flipbox_back {
    min-height: 350px !important;
  }
  .slider_area .appointment {
    display: none;
  }
  .text_center .slider_text_desc p {
    width: 88%;
  }
  .headingh_experience_number h1 {
    float: none !important;
  }
  .heading_experience_title h2 {
    font-size: 34px !important;
  }

  .left .single_service_brg_thumb {
    display: none;
  }
  .left .single_service_inner_thumb {
    margin-top: 0 !important;
    margin-left: 0 !important;
    text-align: center;
  }
  .right .single_service_brg_thumb {
    display: none;
  }
  .right .single_service_inner_thumb {
    margin-top: 0 !important;
    margin-left: 0 !important;
    text-align: center;
  }
  .choose_us .techno_flipbox .techno_flipbox_font,
  .choose_us .techno_flipbox .techno_flipbox_back {
    min-height: 400px !important;
  }
  .call_do_action {
    padding-bottom: 70px !important;
  }
  .single_banner_thumb {
    display: none;
  }
  .about_shape_thumb {
    bottom: 49px !important;
    right: 233px !important;
  }
  .testimonial_style_three_content {
    padding: 23px 28px 30px 10px !important;
  }
  .testimonial_style_three_content::before {
    left: -130px !important;
    width: 169% !important;
  }
  .testimonial_style_three_text p {
    font-size: 18px !important;
  }
  .testimonial_style_three_thumb {
    width: 56%;
  }
  .testimonial_style_three_content {
    padding: 10px 0 0 !important;
  }
  .banner2 {
    height: 700px !important;
  }
  .service_style_eleven_title h4 {
    font-size: 19px !important;
  }
  .software_shape_thumb_inner4 {
    top: 0 !important;
  }
  .section_title.white .section_main_title h1,
  .section_title.white .section_main_title h2 {
    font-size: 34px !important;
  }
  .top-header {
    display: none !important;
  }
  .coll {
    justify-content: end;
    display: flex;
  }
  .single_contact_now_content p {
    font-size: 13px !important;
  }
  .single_contact_now_icon img {
    width: 78%;
  }
  .feature_area {
    padding-top: 30px !important;
  }
  .service_text{
    font-size:35px;
  }
}

/* Large Mobile :600px. */

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .lines:first-child,
  .lines:nth-child(3),
  .lines:nth-child(2),
  .lines:nth-child(4),
  .lines:nth-child(5),
  .lines:nth-child(6) {
    border-left: 0 !important;
    border-bottom: 0 !important;
  }
  .service_inner_text{
    font-size:15px;
  }
  .it_solution{
    display: none;
  }
  .it_image{
    width: 41%;
    left: 60%;
    position: relative;
    top: -17%;
  }

  .links{
    justify-content:left !important;
    top:10px !important;
  }
  .social-icon-footer li{
    padding-right:12px !important;
    padding-left: 0px !important;
  }
  .widgets-company{
    top:10px ;
    position: relative;
  }
  .social-icon-footer{
    top:-3px;
    position: relative;
    left:-4px;
    justify-content: left;
  }
  .footer_middle_area {
    padding-top: 0px ;
  }
  .top-header{
    display: none !important;
  }
  .footer_middle_area {
    top:12px;
    position: relative;
  }
  .lines:first-child {
    border-left: 0;
  }
  .lines:nth-child(5) {
    border-bottom: 0;
    border-left: 0;
  }
.companies_list_text{
  margin-top:0px ;
margin-bottom: 12px;
}
.companies_list_text h1{
  font-size:25px;
}
.slider{
  margin:0 auto 50px;
}
.service1 ,.Service2{
  display: none;
}
.single_counter {
  padding-bottom: 12px;
}
.service_area {
  padding-bottom: 30px !important;
  padding-top: 30px;
}
.process_text h6{
  font-size:14px;
}
  h1.service_text {
    font-size: 25px !important;
  }
  .single_testimonial_content_text p{
    font-size: 12px ;
  }
  .single_testimonial_content_text{
    padding:15px 20px 15px 20px ;
  }
  h3.service_inner_text {
    font-size: 15px !important;
  }
  .service_inner_in_text {
    font-size: 22px !important;
  }
  .navbar-collapse {
    /* max-height: 100px;
    margin-right: auto !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important; */
  }
  .header_top_menu {
    display: none;
  }
  .coll {
    justify-content: end;
    display: flex;
  }
  .owl-prev,
  .owl-next {
    display: none;
  }
  .about_thumb {
    position: relative;
    width: 100%;
  }
  .slider_text_inner h1 {
    font-size: 36px !important;
  }
  .slider_text_inner h5 {
    font-size: 16px !important;
  }
  .slider1,
  .slider2 {
    height: 700px !important;
  }
  .single_about_shape_thumb {
    display: none;
  }
  .section_main_title h1,
  .section_main_title h2 {
    font-size: 40px;
  }
  .team_area .button.two {
    display: none;
  }
  .single_it_work_content_list::before {
    display: none;
  }
  .call_do_action .section_title {
    text-align: center;
  }
  .call_do_action .single-video {
    text-align: center;
    margin-top: 30px !important;
  }
  .counter_area .nagative_margin {
    margin-top: 0 !important;
  }
  .counter_area {
    padding-top: 100px;
  }
  .blog_area .section_button {
    display: none !important;
  }
  .single_blog_thumb img {
    width: 100%;
  }
  .footer-bottom-content-copy p {
    text-align: center !important;
  }
  .footer-bottom-right-text {
    text-align: center !important;
    padding-bottom: 16px;
  }
  .em-nivo-slider-wrapper:hover .nivo-nextNav {
    right: -30px !important;
  }
  .em-nivo-slider-wrapper .nivo-directionNav .nivo-prevNav {
    margin-left: -50px !important;
  }
  .em-slider-sub-title {
    display: none;
  }
  .text-center .em-slider-descript {
    display: none;
  }
  .em-slider-main-title {
    font-size: 85px !important;
  }
  .em-button-button-area a {
    margin-top: 0 !important;
  }
  .em-nav-slider-title-center {
    font-size: 45px !important;
  }
  .em-nivo-slider-wrapper .nivo-directionNav a {
    height: 55px !important;
    width: 28px !important;
    line-height: 54px !important;
    font-size: 30px !important;
  }
  .single_slider_shape_image img {
    display: none;
  }
  .single_slider_rot_inner {
    display: none;
  }
  .slider3 {
    height: 750px !important;
  }
  .slider_text_desc p {
    width: 88% !important;
  }
  .video_shape {
    display: none;
  }
  .video_thumb {
    margin: 0 !important;
    padding-bottom: 120px;
  }
  .nav-tabs .nav-link {
    padding: 22px 38px !important;
    font-size: 18px !important;
    margin-bottom: 9px;
  }
  .single_slider_shape_image {
    display: none;
  }
  #section-quote .container-pe-quote.right {
    display: none;
  }
  #section-quote .container-pe-quote.left {
    display: none;
  }
  .testimonial-bg {
    padding-bottom: 0 !important;
  }
  .nagative_margin3 {
    margin-top: 0 !important;
    padding-top: 70px;
  }
  .slider_area .appointment {
    display: none;
  }
  .button.two {
    text-align: center;
  }
  .prfs_experience {
    padding-top: 65px !important;
  }
  .heading_experience_title h2 {
    font-size: 30px !important;
  }

  .left .single_service_brg_thumb {
    display: none;
  }
  .section_main_title h1{
    font-size: 25px !important;
  }
  .section_main_title span{
    font-size: 14px !important;
  }
  .single_testimonial_content_title span{
    font-size:13px;
  }
  .single_testimonial_content_title h4{
    margin-top:20px ;
    font-size: 15px;
  }
  .section_content_text  p{
    font-size: 15px !important;
  }
  .left .single_service_inner_thumb {
    margin-top: 0 !important;
    margin-left: 0 !important;
    text-align: center;
  }
  .right .single_service_brg_thumb {
    display: none;
  }
  .right .single_service_inner_thumb {
    margin-top: 0 !important;
    margin-left: 0 !important;
    text-align: center;
  }
  .team_style_two {
    margin-top: 30px;
  }
  .call_do_action {
    padding-bottom: 70px !important;
  }
  .choose_us_area .section_button {
    text-align: right;
    display: none;
  }
  .single_banner_thumb {
    display: none;
  }
  .banner_area .data_science_video_inner {
    left: 45% !important;
  }
  .testimonial_style_three_thumb {
    width: 30%;
  }
  .testimonial_style_three_content {
    padding: 15px 0 0 !important;
  }
  .testimonial_style_three_content::before {
    left: -120px !important;
    width: 143% !important;
  }
  .banner2 {
    height: 700px !important;
  }
  .banner3 {
    height: 700px !important;
  }
  .footer-social-info p {
    top: -32px;
    left: 7px;
  }
  .right_button{
    justify-content: normal !important;
     padding-top: 15px !important ;
  }
  .about_contanct_text h1{
    font-size:35px ;
   
  }
  .choose_us_title h4{
    font-size:20px ;
  }
  .choose_us_title_inner  h1{
    font-size:24px ;
  }
  .text_choose_us{
    padding: 20px ;
  }
  .feature_area {
    padding-bottom: 20px !important;
  }
  .imag{
    height: 400px !important;
  }
  .single_contact_now{
    padding: 50px !important;
  }
}
/* small mobile :320px. */
@media only screen and (min-width: 320px) and (max-width: 599px) {
  .right_button button {
    padding: 10px ;
  }
  .about_area{
    padding-bottom: 2rem !important;
  }
  .breatcome_area {
    display:none;
  }
  .slide img{
    width:175px !important;
  }
  .about_thumb img{
    left: 37px;
    padding-top: 16px;
    padding-bottom: 20px;
    top: 25px;
    width: 87% !important;
 
  }
  .singel-about-content p{
    font-size:12px;
  }
  .it_care_bt{
    padding:8px 15px 8px 15px;
    font-size:12px;
 
  }
  .choose_us_title h4{
    font-size: 15px !important;
  }
  .about_contanct_text {
    padding: 10px;
  }
  .about_contanct_text h1{
    font-size:26px ;
  }
  .choose_us_title_inner  h1{
    font-size:20px !important;
  }
  .text_choose_us .row .col p{
    font-size: 12px !important;
  }
  .single_slide_content_text{
    padding:18px !important;
    min-height: 290px !important;
  }
  .single_inbox_text p{
    font-size: 12px !important;
  }
  .single_inbox_text h3{
    font-size:20px !important;
  }
  .single_inbox_text img{
    max-width:60px;
  }
  .slide-track{
    width:calc(155px* 14);
  }
  .service_inner_text{
    font-size: 12px ;
  }
  .lines span{
    font-size: 12px;
  }
  .service_image{
    width:80px;
    padding-bottom: 8px;
  }
  .service_inner_in_text{
    font-size: 15px;
  }
  .card-body p{
    font-size:13px !important;
    line-height: 20px;
    height: 55px !important;
  }
  .card{
    min-height: 488px !important;
    padding: 15px;
    min-height: 475px ;
    margin-bottom: 15px !important;

  }
  .cards{
    width:70%;
  }
  .cards-image{
    height:250px;
  }
  .team_text{
    padding-top:35px;
    margin-bottom: 12px !important;
  }
  .team_text h1{
    font-size:22px;
    padding-top: 0px !important;
  }
  .team_text h4{
    font-size:15px;
  }
.card-body .content div h3{
  font-size: 17px !important;
}
.card-body .content div span{
  font-size: 12px !important;
}
.information{
  font-size: 12px ;
}
.job-section{
  padding-bottom: 20px !important;
}
.cardssssss.card .card-body{
  padding: 15px 20px 15px 20px !important;
}
.buttons button{
  font-size:10px ;
  margin-top:20px;
}
  /* .imag{
    height: 260px !important;
  } */
  .techli{
    padding: 5px !important;
    font-size: 12px !important;
  }
  .top-header{
    display: none !important;
  }
  .feature_area {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .companies_list_text {
    margin-top: 50px !important;
  }
  .slice {
    margin-top: 50px !important;
  }
  .choose_us {
    padding: 50px;
  }

  .section_main_title {
    padding-top: 12px;
  }
  .text_overlay p {
    font-size: 1rem;
    padding-top: 0px;
  }
  .text_overlay h1 {
    font-size: 2rem;
  }
  /* .slick-prev {
    left: -10px !important;
  }
  .slick-next {
    right: -10px !important;
  } */
  .lines {
    flex: auto;
    border-left: 1px solid;
    border-bottom: 1px solid;
    padding-bottom: 10px;
    padding-top: 10px;
    border-color: rgba(0, 0, 0, 0.2);
  }
  .lines:first-child,
  .lines:nth-child(3),
  .lines:nth-child(2),
  .lines:nth-child(4),
  .lines:nth-child(5),
  .lines:nth-child(6) {
    border-left: 0 !important;
    border-bottom: 0 !important;
  }
  .contact-us .container {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .section_sub_title h6 {
    font-size: 15px !important;
  }
 
  .service_area {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .counter_desc span {
    font-size: 12px;
  }
  .single_service_inner_thumb img {
    display: none;
  }
  .single_counter{
    padding-bottom: 10px;
  }
  .counter_desc {
    padding-bottom: 15px;
  }
  .it_solution {
    display: none;
  }
  .process_text h6 {
    font-size: 10px;
  }
  .single_counter img{
    width:65px;
  }
  .counter_desc h5{
    margin-bottom: 5px ;
    margin-top: 17px;
    font-size: 17px;
  }
  .process_text h1 {
    font-size: 20px;
  }
 
  .companies_list_text h1 {
    font-size: 20px;
  }
  .companies_list_text span{
    font-size: 15px !important;
  }
  h1.service_text {
    font-size: 20px;
  }
  h3.service_inner_text {
    font-size: 12px !important;
  }
  .quote_wrapper{
    padding:20px;
  }
  .top-header {
    display: none !important;
  }
  .logo_header {
    display: flex;
    justify-content: space-between;
  }
  .header_top_menu {
    display: none;
  }
  .widget-title {
    top: -32px;
    left: 11px;
  }
  .box_choose_us .container {
    min-width: 390px;
  }
  .inbox_text {
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 17px;
  }
  .testimonial_area {
    padding-bottom: 0rem !important;
    padding-top: 1rem !important;
  }
  .single_testimonial_content_title h4{
    font-size: 15px;
  }
  .technology{
    padding-top: 0px !important;
 padding-bottom: 0px !important;

  } 
  .technology .tech-list{
    padding-bottom: 15px !important;
  }
  .section_title{
    margin-bottom: 12px !important;
    padding-top: 0px !important;
  } 
  .heading_experience_text p {
    font-size: 12px;
  }
  .team_text_inner {
    padding-bottom: 10px;
  }
  .single_contact_now_inner {
    padding: 8px 10px ;
    width:78%;
  }
  .category{
    font-size:12px;
    padding: 3px 0px 3px 0px;
  }
.cards{
  width:65% !important;
}
.team_name{
  font-size:18px !important;
}
  .about_contanct {
    margin-top: 30px;
  }
  .section_main_title span {
    font-size: 17px;
  }
  .bt_number {
    margin-top: 20px;
    left: -54px;
    position: relative;
  }
  .video_thumb img{
    left: 24px;
    padding-top: 42px;
    padding-bottom: 32px;
    top: 25px;
    width: 90% !important;
    position: relative;
  } 
  .right_button span h6{
    font-size:15px ;
    font-weight: normal;
  }
  .whyChooseUsimage {
    display: none;
  }
  .team_slide {
    margin-bottom: 30px;
  }
  .text_choose_us {
    padding: 25px;
  }
  .single_testimonial_content_text {
    padding: 15px;
  }
  .footer_middle_area .container .row {
    padding: 20px !important;
  }

  .owl-prev,
  .owl-next {
    display: none;
  }
  .about_thumb {
    position: relative;
    width: 100%;
  }
  .slider_text_inner h1 {
    font-size: 25px !important;
  }
  .slider_text_inner h5 {
    font-size: 14px !important;
  }
  .slider1,
  .slider2 {
    height: 600px !important;
  }
  .slider-video {
    display: none;
  }
  .button .active {
    display: none;
  }
  .single_about_shape_thumb {
    display: none;
  }
  .section_main_title h1,
  .section_main_title h2 {
    font-size: 25px;
  }
  .card-title {
    font-size: 20px !important;
  }
  .grid-item {
    padding-top: 12px !important;
  }
  .portfolio-filter li {
    padding: 10px !important;
  }
  .cardssssss {
    padding: 10px !important;
    margin-bottom: 12px !important;
  }
  .single_contact_now_icon {
    width: 30%;
  }
  .section_main_title_contact h1{
    font-size: 25px;
  }
  .single_contact_now_content p {
    font-size: 13px !important;
  }
  .button-Contact{
    padding-right: 20px ;
    padding-left: 20px;
    font-size: 12px;
  }
  .quote_wrapper form h5 {
    font-size: 12px;
  }
  .quote_wrapper form h1 {
    font-size: 19px;
  }
  .team_area .button.two {
    display: none;
  }
  .single_it_work_content_list::before {
    display: none;
  }
  .single_it_work {
    margin-bottom: 60px !important;
  }
  .call_do_action .section_title {
    text-align: center;
  }
  .call_do_action .single-video {
    text-align: center;
    margin-top: 30px !important;
  }
  .counter_area .nagative_margin {
    margin-top: 0 !important;
  }
  .section_main_title h1{
    font-size: 20px !important;
  } 
  .section_content_text p{
    font-size: 15px;
  }
  .section_main_title span{
    font-weight: normal !important;
    font-size: 11px !important;
  }
  .single_service img{
    width:20px  !important;
    height: 20px !important ;
  }
  .counter_area .container .row{
    margin-top: 30px !important;
  }
  .countr_text h1 {
    font-size: 32px !important;
  }
  .blog_area .button.two {
    display: none !important;
  }
  .subscribe_form_send button {
    padding: 15px 29px !important;
    top: 79% !important;
  }
  .footer-bottom-content-copy p {
    text-align: center !important;
  }
  .footer-bottom-right-text {
    text-align: center !important;
    padding-bottom: 16px;
  }
  .recent-post-item {
    margin-top: 7px !important;
  }
  .em-nivo-slider-wrapper:hover .nivo-nextNav {
    right: -30px !important;
  }
  .em-nivo-slider-wrapper .nivo-directionNav .nivo-prevNav {
    margin-left: -50px !important;
  }
  .em-slider-sub-title {
    display: none;
  }
  .text-center .em-slider-descript {
    display: none;
  }
  .em-slider-main-title {
    font-size: 45px !important;
  }
  .em-nav-slider-title-center {
    font-size: 25px !important;
  }
  .em-button-button-area a {
    display: none !important;
  }
  .em-nivo-slider-wrapper .nivo-directionNav a {
    height: 55px !important;
    width: 28px !important;
    line-height: 54px !important;
    font-size: 30px !important;
  }
  .single_slider_shape_image img {
    display: none;
  }
  .single_slider_rot_inner {
    display: none;
  }
  .slider3 {
    height: 650px !important;
  }
  .slider_text_desc p {
    width: 89% !important;
  }
  .slider_text_desc p {
    width: 88% !important;
  }
  .video_shape {
    display: none;
  }
  .video_thumb {
    margin: 0 !important;
    padding-bottom: 80px;
  }
  .main_video {
    margin-top: -130px !important;
  }
  .service_style_two_title h4 {
    font-size: 22px;
  }
  .nav-tabs .nav-link {
    font-size: 18px !important;
    margin-bottom: 9px;
  }
  .single_slider_shape_image {
    display: none;
  }
  #section-quote .container-pe-quote.right {
    display: none;
  }
  #section-quote .container-pe-quote.left {
    display: none;
  }
  .testimonial-bg {
    padding-bottom: 0 !important;
  }
  .nagative_margin3 {
    margin-top: 0 !important;
    padding-top: 70px;
  }
  .feature_style_three:hover .feature_style_tree_content {
    bottom: 15px !important;
  }
  .feature_style_three_title h4 {
    font-size: 18px;
  }
  .section_button {
    display: none;
  }
  .techno_flipbox .techno_flipbox_font,
  .techno_flipbox .techno_flipbox_back {
    min-height: 280px !important;
  }
  .it_work_style_two .techno_flipbox .techno_flipbox_font,
  .it_work_style_two .techno_flipbox .techno_flipbox_back {
    min-height: 350px !important;
  }
  .slider_area .appointment {
    display: none;
  }
  .button a {
    padding: 12px 22px !important;
    font-size: 15px !important;
    margin-bottom: 110px;
  }
  .appointment .slider_button {
    margin: 0 !important;
    margin-top: 30px !important;
  }
  .appointment .slider_text_inner h1 {
    font-size: 32px !important;
  }
  .headingh_experience_number h1 {
    float: none !important;
  }
  .heading_experience_title h2 {
    font-size: 20px !important;
  }

  .left .single_service_brg_thumb {
    display: none;
  }
  .left .single_service_inner_thumb {
    margin-top: 0 !important;
    margin-left: 0 !important;
    text-align: center;
  }
  .single_service_content h4 {
    font-size: 15px;
  }
  .right .single_service_brg_thumb {
    display: none;
  }
  .right .single_service_inner_thumb {
    margin-top: 0 !important;
    margin-left: 0 !important;
    text-align: center;
  }
  .appointment_form_inner {
    padding: 35px !important;
  }
  .appointment_title h1 {
    font-size: 35px;
  }
  .call_do_action {
    padding-bottom: 70px !important;
  }
  .choose_us .techno_flipbox .techno_flipbox_font,
  .choose_us .techno_flipbox .techno_flipbox_back {
    min-height: 400px !important;
  }
  .choose_content_text_lft {
    overflow: hidden;
  }
  .single_banner_thumb {
    display: none;
  }
  .banner_area .data_science_video_inner {
    left: 62% !important;
  }
  .banner_text_content h1 {
    font-size: 35px !important;
  }
  .banner1 {
    height: 650px !important;
  }
  .about_shape_thumb {
    bottom: 37px !important;
    right: 78px !important;
  }
  .service_style_nine_content h4 {
    font-size: 21px;
  }
  .testimonial_style_three_thumb {
    width: 30%;
  }
  .testimonial_style_three_content {
    padding: 10px 0 0 !important;
  }
  .testimonial_style_three_content::before {
    left: -79px !important;
    width: 145% !important;
  }
  .testimonial_style_three_text p {
    font-size: 17px !important;
  }
  .testimonial_style_three_title h4 {
    font-size: 19px;
  }
  .testimonial_style_three_quote {
    margin-top: 10px !important;
  }
  .style_two .subscribe_bg_box form {
    padding: 30px 15px 25px !important;
    border-radius: 20px !important;
  }
  .style_two .subscribe_form_send button {
    padding: 17px 25px !important;
    right: 26px !important;
    top: 73% !important;
  }
  .footer_middle_social_icon a {
    margin: 7px 5px !important;
  }
  .pricing_style_four_body ul li {
    font-size: 16px !important;
  }
  .banner2 {
    height: 700px !important;
  }
  .banner2 .banner_text_content h1 {
    font-size: 25px !important;
  }
  .banner2 .banner_text_content.white h5 {
    font-size: 18px;
  }
  .banner3 .banner_text_content h1 {
    font-size: 32px !important;
  }
  .software_shape_thumb {
    display: none;
  }
  .tp-caption a {
    display: none !important;
  }
  .footer-bottom-content-copy p {
    font-size: 11px;
    text-align: left;
    width: 56%;
  }
  .job-section .card {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 1399px) {
  header .navbar .navbar-nav a.nav-link {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  header .navbar .navbar-collapse {
    position: absolute;
    background: #ffffff;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 100%;
    max-width: 697px
  }

  header .navbar {
    padding-bottom: 0;
  }

  header .navbar .navbar-nav .navlinks {
    left: 0 !important;
    width: 100%;
  }

  header .navbar .navbar-nav a.nav-link {
    color: #000000;
  }

  .header .navbar .navbar-nav a.nav-link {
    padding-bottom: 0;
    padding-top: 10px;
    font-size: 14px;
    margin: 0 15px;
  }
}

@media (max-width: 767px) {
  header .navbar .navbar-collapse {
    max-width: 484px;
  }
  .slider8 {
    background-position: 82%;
    height:100vh;
  }

  .color_black .slider_text_inner h1,
  .color_black .slider_text_desc p {
    color: #fff;
  }
  .feature_style_five_title h4 {
    font-size: 16px;
  }
  .about_thumb {
    position: relative;
    width: 100%;
  }
  .technology .tech-list li {
    width: 50%;
  }
  .technology .tech-list li img {
    width: 100%;
  }
  .technology .nav-tabs .nav-link {
    padding: 5px;
    margin-bottom: 0;
  }
  .slider8 .button {
    display: flex;
  }
  .slider8 .button a {
    margin-bottom: 0;
    display: block;
    margin-left: 0;
    margin-right: 5px;
  }
  .single_service_inner_thumb img {
    width: 100%;
  }
  .style_three .footer-social-info p {
    text-align: center;
  }
  .style_three .footer-social-info p span,
  .style_three .footer-social-info p span p {
    display: flex;
    flex-direction: column;
  }
  .style_three .footer-social-info a {
    justify-content: center;
  }
  header .navbar {
    background-color: #fff;
  }
  .acd-items.acd-arrow {
    margin-left: 0;
  }
  .single-panel-thumb-inner img {
    width: 100%;
  }
  .video_thumb {
    padding-bottom: 20px;
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-1 {
    padding-bottom: 15px;
  }
  .whywithus .service_style_three {
    max-height: unset;
    height: auto;
  }
}
@media (max-width:768px){
.grid-item{
  margin: 10px; 
}
}
/* @media (min-width: 425px) and (max-width: 767px){
  .imag{
  height:360px;
  
  }
  .imag img{
    height:200px;
  }
} */
@media (min-width: 992px) and (max-width: 1199px)
  
{
  .bt_subscribe{
    padding:5px 10px 5px 10px ;
    font-size:14px;
  }
  .input{
    width:60% !important;
  }
}
@media (min-width: 992px) and (max-width: 2560px){
  .toggle{
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1024px){
  .it_care_text_inner{
    top:30% !important;
  }
}
@media (min-width:1200px) and (max-width:1399px){
  .card-body p{
    margin-bottom: 35px !important;
  }
  .techli{
    font-size: 10px !important;
  }
}
/* @media (min-width:992px) and (max-width:1199px){
  .it_care_text_inner{
    top:45% !important;
  }

} */
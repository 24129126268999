@import url(https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css);
@import url(//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.css);
@import url(//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css);
@import url(assets/css/flaticon.css);

.react-tel-input,
.react-tel-input .form-control {
  width: 100% !important;
  height: 50px !important;
}

.text-danger {
  text-transform: capitalize;
}

.card-img-top
{ 
    object-fit: cover;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    width: auto;
    max-width: 100%;
}
.portfolio_area {
    background-color: #ffff;
}
.imag {
    position: relative;
    overflow: hidden;
    height: 320px;
    border-radius: 35px;
    transition:  0.3s ;
}


.background-portfolio img {
 background-position: center;
 width:100%;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.card .imag img {
    transition: transform 0.3s ease;
}
 .imag::after{
    content: "";
    position: absolute;
    width: 200%;
    height: 0%;
    left: 50%;
    top: 50%;
    background-color: rgba(255, 255, 255, .3);
    transform: translate(-50%, -50%) rotate(-45deg);
    z-index: 1;
}
.card-title{
    justify-content: center;
    display: flex;
    font-size: 24px;
    margin:0;
    padding-bottom:10px;
}
.row
{
    margin-top: 20px;
}

.card
{
    border-radius:40px;
    border: none !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width:100%;
margin-bottom: 15px;
    padding: 20px;
    background-color: #F7F8FD;
min-height: 505px;
    padding: 20px ;


}
.portfoliostart
{
    margin-top: 20px;
}
.techlist
{
    display: flex;
    list-style: none;
justify-content: center;
    align-items: center;
}
.techli
{    cursor: pointer;
    padding: 0px 5px;
    background-color: #7dbae829;
    border-radius: 5px;
    margin: 5px;
    font-size: 13px;
    font-weight: 500;
    color: #2a7fbf;
    padding: 1px 5px 1px 5px;
    min-width:79px;
    text-align: center;
}

.card-text
{
    font-size: larger;
}
.card-body{
    padding:20px 25px 0px 25px !important ;

}
.portfolio-filter li {
    list-style: none !important;
    padding: 0px 5px;
    background-color: #F7F8FD;
    border-radius: 5px;
    margin: 5px;
    font-size: 13px;
    font-weight: 500;
    color: #2a7fbf;
   cursor: pointer; padding: 10px 33px 10px 33px;
}
.portfolio-filter li.active{
    background-color: #118CE9;
    color:#ffff;
    cursor: pointer;
}
.portfolio-filter{
    text-align: center;
    display: flex;

}
.imag:after {
    content: "";
    position: absolute;
      top: -50%;
    left: -60%;
    width: 20%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
  
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%
    )}
.imag:active:after {
    opacity: 0;
  }
.imag:hover:after {
    opacity: 1;
    left: 130%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
@media (min-width: 992px) and (max-width: 1199px) {
    .imag {
        height: 320px;
    }
    .card-body p{
      line-height: 19px;  
      height:35px !important;
      font-size:12px !important;
    }
.card-title{
    font-size: 20px !important;
}
.tech-body{
    height: 32px !important;
}
.techli {
    font-size:13px;
    padding:3px !important;
}
.card{
    min-height:420px;
}
.card .imag img{
    height:300px;
}}
@media (max-width: 1024px ) and(min-width:768px){
    .grid-item {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
    .card .imag img{
        height: 220px !important;
      }
  }
 
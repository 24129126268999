@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap");

* {
  box-sizing: border-box;
}
#checkbox {
  display: none;
}
.toggle {
  position: relative;
  width: 40px;
  cursor: pointer;

  display: block;
  height: calc(4px * 3 + 11px * 2);
}

.toggle .bar {
  height: 2px;
  width: 100%;
  background: #333;
  display: block;
  border-radius: 2px;
  position: absolute;
  transition: 0.3s ease;
}

.toggle .bar--top {
  top: 0;
}

.toggle .bar--middle {
  top: 50%;
  transform: translateY(-50%);
}

.toggle .bar--bottom {
  bottom: 0;
}

#checkbox:checked + .toggle .bar--top {
  transform: translateY(16px) rotate(45deg);
}

#checkbox:checked + .toggle .bar--bottom {
  transform: translateY(-16px) rotate(-45deg);
}

#checkbox:checked + .toggle .bar--middle {
  transform: scale(0);
}
.slider8 {
  background-image: url(../images/slider/slider-9.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}

.slider8::after {
  content: "";
  /* background: linear-gradient(152deg, rgba(0, 0, 0, 0.6138830532212884) 0%, rgb(0 146 255 / 79%) 100%); */
  background: -webkit-linear-gradient(313deg, hsl(0deg 0% 100% / 0%) 40%, hsla(206, 88%, 51%, 1) 100%);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.slider_text_inner {
  display: inline-block;
}

.slider_text_inner h1 {
  font-size: 65px;
  text-transform: none;
  font-weight: 800;
  color: #fff;
  margin: 0;
  margin: 0;
  line-height: 1.1;filter: drop-shadow(1px 4px 19px #1E1E1E);
}

.slider_text_inner h1 span {
  color: #118ce9;
}

.slider_text_inner h5 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0;
  margin: 0 0 24px;
  letter-spacing: 1px;
}

.slider_text_inner p {
  width: 70%;
  font-size: 16px;
  padding: 29px 0 22px;
  color: #fff;
}

.slider_text_desc p {
  color: #fff;
  width: 62%;
  font-size: 18px;
}

.text_center .slider_text_desc p {
  margin: auto;
}

.color_black .slider_text_inner h1 {
  color: #fff;
}

.color_black .slider_text_desc p {
  color: #fff;
}

.color_black .slider_text_inner h5 {
  color: #118ce9;
}

/* Button Css */

.button a {
  background: #118ce9;
padding:10px 30px 10px 30px ;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 44;
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
}

.button .active {
  background: #118ce9;
  margin-left: 25px;
}

.button .active:before {
  background: #118ce9;
}

.button a i {
  margin-left: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade-out animation */
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

.fade-enter {
  animation: fadeIn 0.5s forwards;
}

.fade-exit {
  animation: fadeOut 0.5s forwards;
}



.button a:before {
  position: absolute;
  content: "";
  top: 0px;
  left: -25%;
  width: 0%;
  background: #16c1f7;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: -1;
}

.button a:hover {
  color: #fff;
}

.button a:hover:before {
  width: 180%;
}

.text-center {
  text-align: center;
}

.form-control {
  height: 54px;
  background-color: #fff;
  border-color: transparent;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #e6e6e6;
  padding: 6px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
}

.form-group .btn {
  background: #118ce9;
  border: 0;
  display: inline-block;
  text-align: center;
  border-radius: 7px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #fff;
  width: 100%;
  height: 54px;
}
.about_area {
  background-color: #f1f1f1;
}

.service_area{
  background-color: #ffffff;
}
.team_text_inner {

  padding-top:16px;
}
.breatcome_area {
  background: url(../images//aboutUspage/about-us-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
 position: relative;
  width: 100%;
  position: relative;
  height: 100vh;
}
.breatcome_area img {
  width: 100%;
  height: auto;
}

.text_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white; /* Adjust the text color as needed */
}
.text_overlay p{
  font-size: 2rem;
  padding-top: 20px;
}
.text_overlay h1 {
  margin: 0;
  font-size: 5rem;
  color: white; /* Adjust the font size as needed */
}
.choose_us {
  width: 100%;
  padding:100px;
  background: url(../../assets/images/aboutUspage/whychooseus.png) center
    no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_choose_us {
  background-color: #fff;
  justify-content: center;
}
.text_choose_us {
  justify-content: center;
  display: flex;
  padding: 50px;
}
.team_text {
  justify-content: center;
  align-items: center;
  display: grid;
}
.team_text h4 {
  letter-spacing: 3px;
  color: #118ce9;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
}
.cards,.card-about {
  width: 190px;
  background: white;
  box-shadow: 4px 4px 10px 0px rgb(0 0 0 / 25%);
  border-radius: 20px;
  width: 200px;
  height: auto;
}
.gallery-bg {
  background:url('../../assets/images/aboutUspage/about-us-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  position: relative;
  height: auto;
}
.cards-image,.card-image-about {
  background-color: rgb(236, 236, 236);
  width: 100%;
  height: auto;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}
.team_name {
  text-align: center;
  font-size: 21px;

}
.category {
  text-align: center;
  color: #118CE9;
  font-size: 14px;
  padding-top: 16px;
  text-transform: uppercase;
  padding-bottom: 16px;
}
.contain {
  justify-content: space-between;
  display: flex;
  align-self: center;
}
.right_button {
  justify-content: end;
  display: flex;
  text-align: center;
  align-self: center;
}
.right_button button {
  padding: 10px 25px 10px 25px;
  background-color: white;
  border: none;
  border-radius: 10px;
  color: #118ce9;
  font-weight: 700;
}

.breatcome_area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.breatcome_title {
  text-align: center;
  z-index: 999;
  position: relative;
}

.breatcome_title_inner h1 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}

.footerlogo {
  max-width: 200px;
  margin-left: 15px;
}

.landing .slider_text_desc p {
  margin: auto;
}

.landing .button {
  margin: auto;
}

.landing .big-half-circle.big-half-circle-1 {
  width: 100%;
  margin-bottom: 0px;
  height: 80px;
  position: absolute;
  bottom: 0;
}

.section_sub_title h6 {
  letter-spacing: 3px;
  color: #118ce9;
  font-size: 24px;
  text-transform: uppercase;
}

.section_title.text_center {
  text-align: center;
}

.section_main_title h1,
.section_main_title h2 {
  font-weight: 800;
  margin: 0;
  margin-bottom: 16px;
}
.section_main_title_contact{
  font-weight: 800;
  margin: 0;
  TEXT-ALIGN: center;
  margin-bottom: 16px;
  DISPLAY: flex;
  justify-content: center;
}
.text_left .section_main_title h1 {
  margin: 0;

}
.tech-list {
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 20px;
  list-style-type: none;
  padding: 0;
}

.tech-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tech-item img {
  max-width: 100%;
  height: auto;
}

.tech-list img {
  max-width: 100px;
  margin-bottom: 10px;
}

.tech-list span {
  display: block;
  text-align: center;
}
.section_main_title h1 em {
  color: #118ce9;
  
}

.section_content_text p {
  font-size: 18px;
}

.text_center .section_content_text p {
  width: 75%;
  margin: auto;
  font-size: 19px;
}

.section_content_text.bold p {
  font-size: 22px;
  font-weight: 600;
}

.section_title.white .section_main_title h1,
.section_title.white .section_main_title h2 {
  color: #fff;
}

.section_title.white .section_sub_title h6 {
  color: #fff;
}

.section_title.white .section_content_text p {
  color: #fff;
}

.phone_number h5 {
  letter-spacing: 1px;
  font-weight: 500;
}

.em_bar {
  background: #9b50db none repeat scroll 0 0;
  height: 2px;
  margin: 0 auto 5px;
  width: 62px;
}

.text_left .em_bar {
  margin: 0;
}



@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

.nagative_margin {
  position: relative;
  margin-top: -100px;
}

.top_feature .techno_flipbox .techno_flipbox_font {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border: 0;
}

.top_feature .techno_flipbox .techno_flipbox_back {
  background: #118ce9;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.top_feature .techno_flipbox .techno_flipbox_inner {
  padding: 0 30px;
}

.top_feature .techno_flipbox .techno_flipbox_back::before {
  display: none;
}

.top_feature .icon {
  color: #118ce9;
  font-size: 45px;
  display: inline-block;
  line-height: 46px;
}

.top_feature .techno_flipbox .flipbox_title h3 {
  font-size: 20px;
  color: #232323;
  font-weight: 700;
}

.top_feature.two .techno_flipbox .techno_flipbox_font,
.top_feature.two .techno_flipbox .techno_flipbox_back {
  min-height: 223px;
}

.top_feature.two .techno_flipbox .techno_flipbox_back {
  background: #fff;
}

.top_feature.two .techno_flipbox .techno_flipbox_inner {
  padding: 0 24px;
}

.top_feature.two .icon {
  color: #fff;
  font-size: 40px;
  line-height: 90px;
  height: 90px;
  width: 90px;
  background: #118ce9;
  border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
}

.top_feature.two .techno_flipbox .flipbox_title h3 {
  padding-bottom: 0;
  font-size: 21px;
}

.top_feature.two .techno_flipbox .techno_flipbox_back .flipbox_title h3 {
  color: #232323;
  font-size: 21px;
}

.top_feature.two .techno_flipbox_back .flipbox_desc p {
  color: #616161;
}

.feature_style_five {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  padding: 35px 30px 10px;
  position: relative;
  border-radius: 0px 50px 50px 50px;
  background: #fff;
  box-shadow: 1px 14px 8px -1px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 1px 14px 8px -1px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 1px 14px 8px -1px rgba(0, 0, 0, 0.26);
}

/* Feature Style Nine */
.techno_flipbox_font {
  border: 1px solid #118ce9;
  border-radius: 8px;
}

.flipbox .section_main_title h1 {
  color: #fff;
}

.flipbox .section_sub_title h6 {
  color: #fff;
}

.flipbox .section_title {
  margin-bottom: 30px;
}

.techno_flipbox {
  perspective: 1000px;
  position: relative;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  z-index: 1;
}

.techno_flipbox .techno_flipbox_font,
.techno_flipbox .techno_flipbox_back {
  min-height: 250px;
  height: auto;
  box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.15);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateX(0);
  -webkit-transform: rotateX(0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
  position: relative;
}

.techno_flipbox .techno_flipbox_back::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent -80%, #00247e);
}

.icon {
  font-size: 45px;
  line-height: 45px;
}

.techno_flipbox .techno_flipbox_inner {
  text-align: center;
  padding: 0 25px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  perspective: inherit;
  -webkit-perspective: inherit;
  outline: transparent solid 1px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  z-index: 2;
}

.techno_flipbox_icon {
  font-size: 45px;
  text-align: center;
  display: inline-block;
  color: #fff;
  overflow: hidden;
}

.techno_flipbox_font .flipbox_desc p {
  color: #fff;
}

.techno_flipbox .flipbox_title h3 {
  font-size: 22px;
  padding-bottom: 13px;
  text-transform: capitalize;
  -webkit-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
  color: #fff;
}

.techno_flipbox .techno_flipbox_back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 2px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.techno_flipbox .techno_flipbox_back .flipbox_button a {
  text-transform: capitalize;
  margin-top: 22px;
  background: #fff;
  color: #118ce9;
  display: inline-block;
  padding: 4px 25px;
  border-radius: 5px;
  border: 2px solid #fff;
  transition: 0.5s;
}

.techno_flipbox_back .flipbox_desc p {
  color: #fff;
}

.techno_flipbox .techno_flipbox_back .flipbox_button a i {
  display: none;
}

.techno_flipbox .techno_flipbox_back .flipbox_title h3 {
  padding-bottom: 16px;
  padding-top: 0;
  color: #fff;
  margin-bottom: 0;
}

.techno_flipbox .techno_flipbox_font .flipbox_desc,
.techno_flipbox .techno_flipbox_back .flipbox_desc {
  color: #fff;
}

/* Flipbox Hover Css */

.techno_flipbox:hover .techno_flipbox_font {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.techno_flipbox:hover .techno_flipbox_back {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.techno_flipbox .techno_flipbox_back .flipbox_button a:hover {
  color: #fff;
  border-color: #118ce9;
  background: #118ce9;
}

.pages .techno_flipbox .techno_flipbox_font .flipbox_title h3 {
  color: #444;
}

.pages .techno_flipbox_font .flipbox_desc p {
  color: #666;
}

.singel-about-content {
  overflow: hidden;
}

.singel-about-content ul {
  list-style: square;
  padding-left: 15px;
}

.singel-about-content.boder {
  border-left: 1px solid #ddd;
}

.singel-about-content h5 {
  font-weight: 700;
  margin: 0;
}
.heading_experience_title{
  line-height: 3;
}
.heading_experience_title h2 {
  font-size: 60px;
  font-weight: 800;
  line-height: 1;

  padding-top: 11px;
  display: inline;
}

.heading_experience_text p {
  font-size: 18px;
}

.experience .main_video {
  position: relative;
  margin-top: -285px;
}

/* Data Science About */

.singel_about_left_inner:hover .data_science_about_icon .icon:before {
  background: rgba(12, 90, 219, 0.9);
}

.singel_about_left_inner:hover .data_science_about_icon .icon {
  color: #fff;
}

.single_slider_shape {
  position: relative;
}

.single_slider_shape_image {
  position: absolute;
  right: -250px;
  top: -499px;
  z-index: 1;
}

.single_slider_shape_image img {
  width: 90%;
}

.single_slider_rot_inner {
  position: absolute;
  bottom: -340px;
  left: 50%;
}

.single_slider_rot.two .single_slider_rot_inner {
  left: -45%;
}

.single_slider_sp {
  position: absolute;
  bottom: 370px;
  left: 79%;
}

.single_slider_sp img {
  width: 100%;
  opacity: 0.8;
}

.single_slider {
  position: relative;
  z-index: 99;
}

.single_slider .button .active {
  margin-left: 0;
}

.single_about_shape {
  position: relative;
}

.single_about_shape_thumb {
  position: absolute;
  bottom: -70px;
  z-index: -1;
  left: -94px;
}

.single_about_shape_thumb img {
  width: 70%;
}

.single_about_signesur_thumb {
  float: left;
}

.single_about_signesur_thumb img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.border_ift {
  position: relative;
  z-index: 1;
}

.border_ift:after {
  content: "";
  width: 536px;
  height: 475px;
  background: 0 0;
  position: absolute;
  bottom: 12px;
  right: -41px;
  z-index: -1;
  border: 15px solid #00000008;
  animation: bannerimage_translate 3s infinite;
  animation-play-state: running;
  animation-direction: alternate;
}

@keyframes bannerimage_translate {
  0% {
    transform: translateX(0px) translateY(0px);
  }

  0% {
    transform: translateX(-30px) translateY(-12px);
  }
}

/* Home Three About Shape */

.video_thumb img {
  width: 100%;
}

.video_shape_thumb1 {
  position: absolute;
  top: 80px;
  z-index: -1;
  left: -16px;
}

.video_shape_thumb2 {
  position: absolute;
  top: 16px;
  right: 0;
  z-index: -1;
}

.video_shape_thumb3 {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: -1;
}

.video_shape_thumb4 {
  position: absolute;
  bottom: 83px;
  right: -135px;
  z-index: -1;
}

.main_video {
  position: relative;
  margin-top: -175px;
}

.single-panel-thumb .main_video {
  position: relative;
  margin-top: -325px;
}

/* Data Science Shape Css */

.shape_thumb {
  position: relative;
}

.about_shape_thumb {
  position: absolute;
  bottom: 202px;
  right: 157px;
}

.about_shape_thumb2 {
  position: absolute;
  right: 155px;
  bottom: 70px;
}

.about_shape_thumb_affiliate1 {
  position: absolute;
  bottom: 65px;
  left: 150px;
}

.about_shape_thumb_affiliate2 {
  bottom: 443px;
  position: absolute;
  left: 77px;
}

.about_shape_thumb_affiliate2 {
  bottom: 490px;
  position: absolute;
  left: 45px;
}

/* Affiliate Intelligent About */

.about_icon_box_inner i {
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #118ce9;
  text-align: center;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
  margin-right: 12px;
}

.about_icon_box_inner span {
  font-size: 16px;
  font-weight: 700;
  color: #232323;
}

.service_style_one {
  background: #fff;
  border-radius: 10px;
}

.service_style_one_icon i {
  width: 75px;
  height: 75px;
  line-height: 75px;
  background: #e8ecfc;
  border-radius: 100%;
  color: #118ce9;
  display: inline-block;
  border-radius: 33% 66% 70% 30%/49% 62% 38% 51%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  font-size: 35px;
}

.service_style_one_title h4 {
  font-weight: 700;
  color: #118ce9;
  position: relative;
}

.service_style_one_title h4:before {
  position: absolute;
  content: "";
  left: 0;
  top: 37px;
  height: 1px;
  width: 100px;
  background: #d1d1d1;
  margin: auto;
  right: 0;
}

.service_style_one_button a {
  color: #616161;
  border: 1px solid #e8ecfc;
  padding: 5px 23px;
  border-radius: 30px;
  display: inline-block;
  font-size: 16px;
}

.service_style_one_button a i {
  margin-left: 7px;
}

.service_style_one:hover .service_style_one_button a {
  background-color: #118ce9;
  color: #fff;
  border-color: #118ce9;
}

.service_style_one:hover .service_style_one_icon i {
  background-color: #118ce9;
  color: #fff;
}

.service_style_five {
  border: 1px solid #d5c7c7;
  border-radius: 8px;
  background: transparent;
  transition: 0.5s;
  padding: 25px;
  margin-bottom: 15px;
  height: 340px;
}

.service_style_five_title h4 {
  font-size: 22px;
  font-weight: 700;
  transition: 0.5s;
}

.service_style_five_icon i {
  transition: 0.5s;
}

.service_style_five_text p {
  transition: 0.5s;
}

.service_style_five_button a {
  transition: 0.5s;
}

.service_style_five_button a i {
  margin-left: 5px;
}

.service_style_five:hover {
  border: 1px solid #118ce9;
  background: #118ce9;
  transform: translateY(-8px);
}

.service_style_five:hover .service_style_five_title h4,
.service_style_five:hover .service_style_five_icon i,
.service_style_five:hover .service_style_five_text p,
.service_style_five:hover .service_style_five_button a {
  color: #fff;
}

.single_service_inner {
  padding: 19px 0 5px;
  margin-bottom: 30px;
}

.single_service_inner::before {
  /* position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 60px;
  background: #118ce9;
  content: "";
  z-index: -1;
  border-radius: 0 30px 30px;
  transition: 0.5s;
  border: 1px transparent; */
}
.single_service {
  display: flex;
}
.single_service_icon {
  float: left;
  margin-left: 15px;
  margin-right: 30px;
  margin-top: -5px;
}

.single_service_icon i {
  display: inline-block;
  text-align: center;
  font-size: 25px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  color: #fff;
}

.single_service_content h4 {
  font-size: 22px;
  text-align: left;
  margin: 0 8px 35px;
  transition: 0.5s;
}

.right .single_service_brg {
  position: relative; /* Add position relative */
}

.right .single_service_brg_thumb {
  position: absolute; /* Change position to absolute */
  bottom: -120px;
  left: 25px;
  z-index: -1; /* Set z-index to keep it behind the other content */
}

.left .single_service_inner_thumb {
  margin-top: -144px;
  margin-left: 80px;
}
.single_service_inner_thumb img{
  width:auto;
  max-width: 100%;
}

/* .left .single_service_brg {
  position: relative;
} */

.left .single_service_brg_thumb {
  bottom: 100px;
  position: absolute;
  left: 81px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotate 5s linear infinite;
}
.textss {
  font-size: 20px;
  top: 44px;
  position: relative;
  text-align: center;
  width: 100%;
  font-weight: 800;
}
/* 
.single_service_inner:hover .single_service_content h4 {
  color: #fff;
} */

/* .single_service_inner:hover {
  background: #118ce9;
} */
/* 
.single_service_inner:hover::before {
  background: #fff;
} */

/* .single_service_inner:hover .single_service_icon i {
  color: #118ce9;
} */

.it_solution {
  background-image: url("../images/it_care_bg.png");
  margin-bottom: 50px;

  background-repeat: no-repeat;
  background-size: cover;
}
.it_care_text {
  margin: 0px 130px 0px 0px;
  width: 44%;
  height: 200px;
  position: relative;
}
.it_care_text_inner {
  width: 100%;
  position: absolute;
  top: 65%;
  left: 13%;
}
.it_care_text_inner p {
  padding-top: 20px;
  color: black;
  font-weight: lighter;
}
.circle1 {
  margin-top: -191px;
  width: 22%;
  left: 68px;
  position: relative;
}
.circle2 {
  left: 71%;
  position: relative;
  width: 16%;
  top: 105px;

}
.it_care_bt {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background: #118ce9;
  border: none;
  padding: 10px;
  font-weight: 700;
  color:white;
  padding-right: 30px;
  padding-left: 30px;
}
.it_care_bt::before {
  content: "";
 
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  transition: transform 0.4s ease-in-out;
  transform: skewX(-25deg);
}

.it_care_bt:hover::before {
  transform: translateX(200%);
}

.it_care_bt::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.5) 50%, transparent 100%);
  transform: skewX(-45deg);
  transition: left 0.7s;
}

.it_care_bt:hover::after {
  left: 100%;
}

.button-Contact{
  border-radius: 10px;
  font-weight:700;
  color:#118CE9;
  background:#ffffff;
  border: none;
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
}
.button-Contact:hover{
  background-color: #fff;
  color:#118CE9;
}
.companies_list_text h1 {
  color: black;
  margin-bottom: 16px;
}
.companies_list_text {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 70px;
}
:root {
  --animation-speed: 10s;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
  height: 100px;
  overflow: hidden;
  position: relative;
  width: 1200px;
  margin: 0 auto 70px;
}

.slider::before,
.slider::after {
  /* background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%); */
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slide-track {
  animation: scroll var(--animation-speed) linear infinite;
  display: flex;
  width: calc(250px * 14);
  margin-bottom: 70px;
}
.slide {
  height: 100px;
  width: 50%;
  margin-right: 25px;
  margin-left: 25px;
}

.service-main-details-content-title h3 {
  font-weight: 800;
}

/* Team Style Three */

.team_style_three {
  position: relative;
  transition: 0.5s;
}

.team_style_three-thumb {
  position: relative;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 50px;
}

.team_style_three_thumb_inner {
  position: relative;
  z-index: 1;
  transition: 0.5s;
  overflow: hidden;
}

.team_style_three_thumb_inner:before {
  position: absolute;
  left: -70px;
  bottom: -59px;
  height: 120px;
  width: 133%;
  background: #fff;
  content: "";
  -webkit-transform: skew(-30deg);
  -khtml-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  transform: skew(-98deg);
}

.team_style_three-thumb:after {
  position: absolute;
  left: 0;
  bottom: 4px;
  height: 100%;
  width: 100%;
  background: #118ce9;
  content: "";
  -webkit-transform: skew(1deg);
  -khtml-transform: skew(69deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}

.team_style_three_thumb_inner img {
  width: 100%;
}

.team_style_three_content {
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 18px;
  z-index: 1;
  padding: 15px 10px 35px 50px;
  transition: 0.5s;
}

.team_style_three_content:before {
  background: #118ce9;
  transition: 0.5s;
}

.team_style_three_content:before,
.team_style_three_content:after {
  content: "";
  top: 0;
  left: 0;
  right: 50px;
  bottom: 0;
  position: absolute;
  z-index: -2;
  -webkit-transform: skew(-30deg);
  -khtml-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  transform: skew(-30deg);
  transform-origin: left bottom;
  -webkit-transform-origin: left bottom;
  -webkit-transition: all 0.2s linear 0ms;
  -khtml-transition: all 0.2s linear 0ms;
  -moz-transition: all 0.2s linear 0ms;
  -ms-transition: all 0.2s linear 0ms;
  -o-transition: all 0.2s linear 0ms;
  transition: all 0.2s linear 0ms;
}

.team_style_three_title h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
}

.team_style_three_sub_title {
  transition: 0.5s;
  position: absolute;
  left: 51px;
  top: 40px;
}

.team_style_three_sub_title span {
  font-size: 14px;
  color: #fff;
  transition: 0.5s;
}

.team_style_three_icon a {
  color: #fff;
  display: inline-block;
  margin: 5px;
}

.team_style_three_icon {
  opacity: 0;
  transition: 0.5s;
  position: absolute;
  left: 47px;
  top: 50px;
}

.team_style_three:hover .team_style_three_icon {
  opacity: 1;
  top: 35px;
}

.team_style_three:hover .team_style_three-thumb:after {
  opacity: 1;
  -webkit-transform: rotate(-10deg);
  -webkit-transform: skew(1deg);
  transform: rotate(-10deg);
}

.team_style_three:hover .team_style_three_sub_title {
  /* margin-top: -20px; */
  /* opacity: 0; */
}

.team_style_three:hover .team_style_three_content:before {
  background: #00247e;
  transition: 0.5s;
}

/* Team Style Three */

.single_team_style_three {
  margin-bottom: 30px;
}

.single_team_style_three_thumb {
  position: relative;
}

.single_team_style_three_thumb img {
  width: 100%;
}

.single_team_style_three_icon {
  position: absolute;
  top: 90%;
  right: 0;
  left: 0;
  text-align: center;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  width: 83%;
  background: #118ce9;
  margin: 0 30px;
  margin: auto;
  padding: 12px 0;
  transition: 0.5s;
  opacity: 0;
}

.pdn_0 {
  padding: 0;
}

/* Style Three */

.three .case_study_style_two_content {
  top: 75%;
}

.three .case_study_style_two:hover .case_study_style_two_content {
  top: 60%;
}

.three .case_study_style_two_title h4 {
  font-size: 20px;
}

.portfolio_nav {
  margin-bottom: 50px;
}

.portfolio_menu ul {
  text-align: center;
  list-style: none;
}

.portfolio_menu ul li {
  display: inline-block;
  margin: 0 14px;
  cursor: pointer;
}

.portfolio_menu ul li a {
  display: block;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  transition: 0.5s;
}

.portfolio_menu ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 24px;
  height: 2px;
  width: 0%;
  background: #fff;
  transition: 0.5s;
}

.portfolio_nav ul li {
  background: #f2f4ff;
  border: medium none;
  font-size: 16px;
  line-height: 1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: capitalize;
  padding: 12px 27px;
  font-weight: 500;
  letter-spacing: 2px;
  position: relative;
  margin: 0 3px;
  margin-bottom: 7px;
  display: inline-block;
  border-radius: 5px;
}

.portfolio_nav ul li:hover,
.portfolio_nav ul li.current_menu_item {
  color: #fff;
  background: #118ce9;
}

/* Single Portfolio */

.single_portfolio {
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  transition: 0.5s;
}

.single_portfolio_thumb {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: 0.5s;
}

.single_portfolio_content {
  position: absolute;
  text-align: center;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  right: 20px;
  bottom: 20px;
  left: 20px;
  opacity: 0;
  background: #118ce9;
  margin: auto;
  text-align: left;
  padding: 30px 20px 30px;
  border-radius: 5px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single_portfolio_icon {
  text-align: center;
  padding-bottom: 10px;
}

.single_portfolio_content_inner {
  text-align: center;
}

.single_portfolio_content_inner h2 {
  font-size: 22px;
  margin: 0;
}

.single_portfolio_content_inner h2 a {
  display: inline-block;
  overflow: hidden;
  font-weight: 600;
  color: #fff;
}

.single_portfolio_icon i {
  background: #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  border-radius: 5px;
  color: #135ef3;
  transition: 0.5s;
}

.single_portfolio_thumb img {
  width: 100%;
  object-fit: cover;
  max-height: 300px;
  height: 100vh;
}

.single_portfolio_content_inner span {
  color: #fff;
}

/* Portfolio Hover */

.single_portfolio:hover .single_portfolio_thumb {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.portfolio_menu ul li a:hover:before {
  width: 100%;
}

.single_portfolio:hover .single_portfolio_content {
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
}

.single_portfolio_icon i:hover {
  background: #fff;
  color: #135ef3;
}

.techno_flipbox_font .icon {
  color: #fff;
  font-size: 30px;
  line-height: 80px;
  height: 80px;
  width: 80px;
  background: #118ce9;
  border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
  display: inline-block;
}

.countr_text h1 {
  font-size: 40px;
  font-weight: 800;
}

.counter_desc h5 {
  letter-spacing: 2px;
  margin-top: 24px;
  color: #118ce9;
  margin-bottom:15px;
}
.single_contact_now_inner{
  min-height: 350px;
}

.single_contact_now_content h2{
  font-size: 25px;
  margin-bottom:25px;
}
.single_contact_now_content p{
  font-size:16px;
}
.team_text  
.white .countr_text h1 {
  color: #fff;
}

.white .counter_desc h5 {
  color: #fff;
}

/* Counter Style Three */

.color2 .counter_style_three_icon .icon {
  background: #fd8424;
}

/* Counter Style Four */
.single_testimonial_content_text {
  box-shadow: 0 18px 17px 0 rgba(0, 0, 0, 0.1);
  padding: 25px 26px 25px 26px;
  position: relative;
  background: #F7F8FD;
  border-radius: 20px;
  text-wrap: pretty;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  flex-direction: column;
  align-items: self-start;
}
.inbox_text p {
  font-weight: 14px;
}
/* .single_testimonial_content_text:before {
  content: "";
  bottom: -20px;
  top: auto;
  border-color: #fff transparent transparent #fff;
  display: block;
  position: absolute;
  left: 0;
  margin-left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px;
} */

.single_testimonial_thumb {
  float: left;
}


.single_testimonial_thumb img {
  width: 100%;
  border-radius: 50%;
  margin: auto;
}

.single_testimonial_content_title h4 {
  font-size: 20px;
  margin-top: 30px;
  font-weight: 700;
}

.single_testimonial_content_title span {
  color: #118ce9;
  font-weight: 500;
  font-size: 16px;
  display: block;
  padding-top: 4px;
}

.single_testimonial_content_title.white span {
  color: #fff;
}

.nav-tabs .nav-item {
  margin: 0 5px;
}

.nav-tabs .nav-link {
  border: 1px solid #118ce9 !important;
  background: #118ce9;
  padding: 22px 50px;
  font-size: 20px;
  color: #fff;
}

.nav-tabs .nav-item.active .nav-link {
  border: 1px solid #fff !important;
  background: #fff;
  color: #118ce9;
}

.nav-tabs {
  border-bottom: inherit !important;
}

.nav-tabs .nav-link i {
  font-size: 30px;
  display: block;
  text-align: center;
  padding-bottom: 5px;
}

.tab_content_title h4 {
  font-weight: 700;
}

.tab_content_sub_text ul {
  display: block;
}

.tab_content_sub_text ul li {
  list-style: none;
  margin-bottom: 5px;
}

/* New Choose Us */

.main-accordion-lt {
  background: #2d2d2d;
}

.acd-items.acd-arrow {
  /* margin-left: 160px; */
}

.panel-heading .panel-title a {
  background: #393939;
  display: block;
  font-weight: 600;
  padding: 10px 15px;
  position: relative;
  color: #fff;
  font-size: 18px;
}

.acd-items .panel-heading {
  padding: 0;
}

.panel.panel-default {
  border-color: #e7e7e7;
  border-radius: inherit;
  box-shadow: inherit;
}

.panel-body p {
  color: #cccccc;
}

.default .panel-body p {
  color: #666;
}

.accordion-area .absod {
  padding: 0;
}
.accordion-collapse.collapse.show {
  padding: 20px;
}

.accordion-body {
  padding: 1rem;
  height: 29rem;
}
.panel-heading.mb-3 a i {
  font-size: 15px;
  margin-right: 10px;
}

.panel-group.symb .panel-heading a::after {
  content: "-";
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 7px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.panel-group.symb .panel-heading a.collapsed:after {
  content: "+";
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.form_box input {
  width: 100%;
  border: none;
  padding:0px !important;
  background-color: transparent;

  border-bottom: 1px solid #ffffff50;
  padding-left: 15px;

  border-radius: 0px !important;
  color: #fff !important;
transition: 0.5s;
}
.form_box input:focus,
.form_box textarea:focus {
  border-bottom: 1px solid white !important;
  outline: none; /* Remove default outline */
}
.form_box textarea {
  width: 100%;
  padding-left: 0px;
  padding-top: 20px;
  height: 250px;
  border: 1px solid #fff;
  border-radius: 0px !important;
  color: #fff !important;
  transition: 0.5s;
}
/* 
.quote_btn button {
  background: #fff;
  border: 0;
  display: inline-block;
  text-align: center;
  padding: 14px 39px;
  border-radius: 7px;
  transition: 0.3s;
} */
/* 
.quote_btn button:hover {
  background: #00247e;
  color: #fff;
} */

/* .map {
  width: 100%;
  margin: 0;
  height: 500px;
  border: 1px solid #ddd;
  padding-top: 4px;
} */

.google_map_area .col-lg-12 {
  padding: 0;
}

.it {
  padding-top: 130px;
}

.app .form_box textarea {
  height: 170px;
}

.form-textarea {
  height: 100px !important;
}
/* Contact Address Style Two */
.single_contact_now {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
}

.single_contact_now_inner {
  background: #fff;
  padding: 15px 20px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.single_contact_now_icon img {
  padding-bottom: 30px;
}

.single_contact_now:hover .single_contact_now_inner {
  border-color: #cbbded;
}
/* Style Blog All */

.widget-title {
  color: #ffffffb8;
  font-size: 15px;
  font-weight: 600;
  top: -5px;
  position: relative;
}
.form-control:focus{
  background-color: transparent;
  border: none;
  border-bottom:1px solid white;
  box-shadow: none;
}

.contact-us{
  position: relative;
  background: url('../../assets/images/contanctUspage/contactUs-Form\ .svg') center no-repeat;
  width:100%;
  max-height: 1000px;
  min-height: 820px;
  background-size: cover;

}
input.form-control::placeholder , textarea::placeholder{
  color:white !important;
}
.con,
.page,
.contact {
  color: white !important;
  font-weight: bolder !important;
  font-size: 24px;
 filter: drop-shadow(5px 5px 5px #222);
}
.contact {
  margin-bottom: 12px;
}
.widget .menu-quick-link-container ul li a {
  color: #fff;
  position: relative;
  text-decoration: none;
  font-weight: lighter;
}



.link-button::before {
 
  transition: transform 0.4s ease-in-out;

}

.link-button:hover::before {
  transform: translateX(200%);
}

.link-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.5) 50%, transparent 100%);
  transform: skewX(-45deg);
  transition: left 0.7s;
}

.link-button:hover::after {
  left: 100%;
}

.menu-quick-link-container ul li a:before {
  content: "";
}
.menu-quick-link-container ul li a:hover {
  text-decoration: underline;
}

.menu-quick-link-container ul li {
  padding-bottom: 10px;
  list-style: none;
}

.follow-company-text a {
  font-size: 16px;
  transition: 0.5s;
}
.follow-company-text p {
  color: #fff;
  transition: 0.5s;
}

.follow-company-text {
  float: left;
}

.follow-company-icon a {
  font-size: 16px;
  color: #fff;
  margin: 0 5px;
  font-weight: 500;
  display: inline-block;
  transition: 0.5s;
}

.footer-social-info p span {
  font-weight: 600 !important;
  color: #fff;
}
.footer-social-info p {
  left: -6px;
  position: relative;
  top: -8px;
  margin-top: 25px;
}
.footer-social-info p,
.footer-social-info a {
  color: #fff;
  text-decoration: none;
  top: -5px;
  left: -2px;
  font-weight: lighter;
}

.footer-social-info a {
  position: relative;
}

.follow-company-icon a:hover {
  color: #fff;
  margin-top: 25px;
}

.follow-company-text a p:hover {
  color: #118ce9;
}

.menu-quick-link-container li .fa-arrow-up {
  display: none;
  position: absolute;
  left: 10px;
  bottom: -20px;
}

.menu-quick-link-container li:hover .fa-arrow-up {
  display: inline-block;
  animation: slideFromBottom 0.3s ease forwards; 
}
@keyframes slideFromBottom {
  0% {
    bottom: -20px; 
  }
  100% {
    bottom: 1px;
  }
}

.footer_middle_area {
  overflow: hidden;
}

.footer-bottom {
  background: #118ce9;
  height: 70px;
}
.footer-bottom-content-copy p {
  color: #fff;
}

.footer-bottom-right-text {
  text-align: right;
}

.footer-bottom-right-text a {
  color: #fff;
  display: inline-block;
}

.footer-bottom-right-text .absod {
  margin-right: 30px;
  position: relative;
}

.footer-bottom-right-text .absod::before {
  position: absolute;
  content: "";
  right: -17px;
  top: 6px;
  height: 14px;
  width: 1px;
  background: #fff;
  transform: skew(-20deg);
}

/* COMMENT FORM CSS */

.error_page_area {
  padding: 65px 0 98px;
}

.single_error_inner {
  text-align: center;
}

.single_error_inner h1 {
  font-size: 150px;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 30px;
}

.single_error_inner h1 span {
  color: #118ce9;
}

.single_error_inner h2 {
  padding-bottom: 16px;
  font-size: 28px;
}

.single_error_button {
  margin-top: 30px;
}

.single_error_button a {
  background: #fff;
  color: #118ce9;
  padding: 11px 26px;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
  border: 2px solid #118ce9;
}

.single_error_button a:before {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background: #118ce9;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: -1;
}

.single_error_button a:hover {
  color: #fff;
}

.single_error_button a:hover:before {
  width: 180%;
}

.technology .nav-tabs {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid rgba(3, 101, 175, 0.5) !important;
}

.technology .nav-tabs .nav-link {
  border: none !important;
  outline-style: none;
  /* border-bottom: 2px solid transparent !important; */
  background-color: transparent;
  color: #232323;
  padding: 15px;
  font-size: 16px;
  transition: all 0.5s;
}

.technology .nav-tabs .nav-link.active {
  background-color: #118ce9 !important;
  color: #fff;
  transition: all 0.5s;
}

.technology .tech-list {
  padding: 0px;
  list-style: none;
  text-align: center;
}

.technology .tech-list li {
  display: inline-block;
  background: #ffffff;

  border-radius: 20px;
  width: 190px;
  margin: 10px;
}

.technology .tech-list li span {
  display: block;
  font-weight: 700;
  font-size: 14px;
  padding: 5px;
}
.testimonial_area {
  background-color: #ffffff;
}

.technology .tech-list li img {
  max-width: 155px;
}
.technology {
  background-color: #f1f1f1;
}


.contact_area::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section_title.text_center,
.quote_wrapper {
  position: relative;
  z-index: 999;
}

.quote_wrapper {
  /* background-image: url(../images/cn-bg.jpg); */
  /* background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%); */
  /* background: #fff; */
  padding: 50px 20px;
  border-radius: 5px;
}
.login_wrapper {
  background-image: url(../images/cn-bg.jpg);
  padding: 50px 20px;
  border-radius: 5px;
}
.counter_area,
.about_contanct {
  background-image: url(../../assets/images/cn-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.about_contanct {
  width: 100%;
  height: 290px;
  margin: 70px 0px 0px 0px;
  align-items: center;
  display: flex;
}
.process_text {
  text-align: center;
}
.process_text h6 {
  font-weight: 100;
  line-height: 12px;
}
.call_do_action {
  background-image: url(../images/call-bg.png);
}
.bg_color2 {
  background-color: #f1f1f1;
}
/* .single_testimonial {
  margin: 0 15px;
} */

.slick-prev:before,
.slick-next:before {
  color: #03a9f4;
}

.navbar-brand img {
  width: auto;
  height: auto;
  max-width: 204px;
}
.navbar-collapse {
  display: flex;
  justify-content: center;
  align-items: center;
}
header .navbar {
  left: 0;
  margin: auto;
  position: fixed !important;
  top: 0;
  width: 100%;
 
  backdrop-filter: saturate(134%) blur(4.875rem);
  background-color: rgb(255 255 255 / 0%);
  transition: 0.5s;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .navbar.scrolled .row{
   margin: top 0px !important;}
header .navbar.scrolled {
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.82);
  padding-top: 0px;
}
.header-content {
  display: flex;
  align-items: center;
}
.service_text {
  font-weight: bold;
  text-align: center;
}
.service_inner_text {
  color: #6a6a6a;
  text-align: center;
  font-weight: lighter;
  font-size: 20px;
}
.service_image {
  width: 100px;
  height: 100px;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  padding-bottom: 40px;
}
.service_inner_in_text {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  transition: 0.3s;
  line-height: 28.13px;
  color: rgba(0, 0, 0, 1);
  padding-bottom: 14px;
}
@keyframes vertical-shake {
  0% { transform: translateY(0); }
  25% { transform: translateY(-10px); }
  50% { transform: translateY(-2px); }
  75% { transform: translateY(5px); }
  100% { transform: translateY(0); }
}
.service_image {
  transition: transform 0.5s ease-in-out;
}
.lines:hover .service_image {
  animation: vertical-shake 0.5s ease-in-out;
}
.each_process img {
  transition: transform 0.5s ease-in-out;
}
.each_process:hover img {
  transform: scale(1.1); 
}
.lines span {
  text-align: center;
  font-size:15px;
  display: flex; 
  justify-content: center;
}
.col span {
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: 17px;
  font-weight: 300;
  line-height: 20px;
}
.lines {
  flex: auto;
  border-left: 1px solid;
  border-bottom: 1px solid;
  padding: 25px 10px ;

  border-color: rgba(0, 0, 0, 0.2);
}
.lines:first-child {
  border-left: 0;
}
.lines:nth-child(5) {
  border-bottom: 0;
  padding-top: 30px;
}

.lines:nth-child(6) {
  border-bottom: 0;
  padding-top: 30px;
}
.lines:nth-child(4) {
  border-left: 0;
  padding-top: 30px;
  border-bottom: 0;
}
.phone {
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  position: absolute;
  cursor: pointer;
}

.cta {
  height: 40px;
  width: 168px;
  color: white;
  z-index: 1;
  transition: 1.2s;
  background: linear-gradient(to right bottom, #4776e6, #8e54e9);
}
.number {
  height: 40px;
  width: 180px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-size: cover;
  color: white;
  box-shadow: 3px 3px 30px #aaa;
}
.cta:hover {
  transform: translateX(-80%);
  transition: 1.2s;
}
.fa-phone:before {
  content: "\f095";
  color: white;
}

.hide-text {
  display: none;
}
.navbar.dark-header {
  background-color: transparent; /* Or your desired color for the homepage */
  transition: background-color 0.3s ease;
}

.navbar.white-header {
  background-color: white; /* Or your desired color for other pages */
  transition: background-color 0.3s ease;
}
.navbar.dark-header .nav-link,
.navbar.dark-header .phone_no {
  color: white; /* Or your desired color for the homepage */
}
.it_care_text_inner h1{
  font-weight:900;
}
.navbar.white-header .nav-link
  {
  color: black !important; 
}
.navbar.white-header .top-header ul li, .navbar.white-header .top-header .fa-phone:before ,.navbar.white-header .top-header .phone_no, .navbar.white-header .top-header  .fa-envelope:before{
  color: #118ce9 !important;
}
header .navbar .navbar-nav a.nav-link {
  padding-top: 8px;
  font-size: 18px;
}

header .navbar.scrolled .navbar-nav a.nav-link {
  color: black;
  font-weight: 600;
}
header .navbar .scrolled .navbar-nav a.nav-link .active {
  color: #03a9f4;
}
.scrolled button.getQuote {
  background-color: #118ce9;
  color: white;
  margin:0px;
}
.scrolled .top-header{
  display: none !important;
}
header .navbar.scrolled .navbar-nav .navlinks{
  padding-top: 8px;
  left:-8px;
  position:relative;
}
.getQuote {
  top: 10px;
  position: relative;
  border: none;
  border-radius: 10px;
  color: #118ce9;
  font-weight: 700;
  margin-bottom: 12px;
  padding: 10px 25px 10px 25px;
}
@keyframes underline {
  from {
    width: 0;
    left: 0;
  }
  to {
    width: 100%;
    left: 0;
  }
}
.nav-link:hover::after {
  width: 100%;
  animation: underline 0.3s forwards;
  color: #007bff;
}

.nav-link {
  position: relative;
}

.ms-auto.navbar-nav {
  padding-top: 0px;
}
li svg {
  margin: 0px 10px 0px 10px;
}
.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
}
.nav-link {
  margin: 0 10px;
}

header .navbar .navbar-nav a.nav-link.active, header .navbar .navbar-nav a.nav-link:hover {
  color: #007bff !important;
}

.upper_text {
  margin-right: auto;
  display: flex;
}
/* header .navbar::after {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
filter: blur(1px);
} */
.about-pages p {
  color: #fff;
  font-weight: 400;
}
.single_portfolio {
  max-height: 420px;
  height: 100%;
}
.grid:after {
  content: "";
  display: block;
  clear: both;
}

.grid-item {
  float: left;
  height: 420px;
}
.service-box a {
  text-decoration: none;
}

.service-box a p {
  color: #0d1218;
}

footer {
  background: linear-gradient(to left, #002d97, #0a4f83);
}

.testimonial_list .slick-prev:before,
.testimonial_list .slick-next:before {
  color: rgba(3, 101, 175, 1);
}

.career-bg {
  background-color: rgba(3, 101, 175, 0.05);
}

.accordion-header {
  margin-top: 0;
}

.btn-primary {
  background: #16c1f7;
  border-color: #16c1f7;
}

.career-list {
  height: auto;
}

.job-detail {
  background-color: #118ce9;
}

.job-detail hr {
  margin: 25px 0;
}

.job-detail h4 {
  color: #fff;
  margin: 15px 0;
}

.job-detail h5 {
  color: #f1f1f1;
}

.single_team {
  overflow: hidden;
  border-radius: 5px;
  transition: 0.5s;
}

.single_team_thumb {
  position: relative;
}

.single_team_thumb:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 90, 219, 0.85);
  content: "";
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.single_team_content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #fff;
  padding: 12px 20px 23px;
  text-align: center;
  border-bottom: 2px solid #0c5adb;
  text-transform: capitalize;
}

.single_team:hover {
  transform: translateY(-10px);
}

.single_team {
  overflow: hidden;
  border-radius: 5px;
  transition: 0.5s;
}

.image-notfound {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-notfound h4 {
  position: absolute;
  left: 80px;
  top: 45px;
}

.toast {
  position: fixed;
  top: 100px;
  right: 10px;
  color: #fff;
  z-index: 9999999;
}

.image-hover:hover {
  opacity: 0.5;
}

body {
  background: #f5f5f5;
}

.services-block-three {
  margin-bottom: 30px;
}

.services-block-three i {
  font-size: 32px;
}

.services-block-three > a,
.services-block-three .a {
  display: block;
  border: 2px solid #d5d5d5;
  border-radius: 5px;
  text-align: center;
  background: #fff;
  /* padding: 20px; */
  padding: 10px;
  position: relative;
  margin-bottom: 30px;
  text-decoration: none;
}

.services-block-three > a:before {
  display: block;

  width: 9%;
  height: 17%;
  position: absolute;
  bottom: -2px;
  right: -2px;
  border-bottom: 2px solid #03a9f5;
  border-right: 2px solid #03a9f5;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

.services-block-three > a:after {
  display: block;

  width: 9%;
  height: 17%;
  position: absolute;
  top: -2px;
  left: -2px;
  border-top: 2px solid #03a9f5;
  border-left: 2px solid #03a9f5;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

.padding-15px-bottom {
  padding-bottom: 15px;
}

.services-block-three h4 {
  color: #6f6f6f;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.services-block-three p {
  margin-bottom: 0;
  color: #36a8df;
  font-weight: bold;
  text-transform: capitalize;
}

.services-block-three > a:hover {
  opacity: 1;
  border-color: #d5d5d5;
}

.fa-mobile:before {
  content: "\f3ce";
  color: #118ce9;
  left: 12px;
  position: relative;
}
.fa-inbox:before {
  content: "\f01c";
  color: #118ce9;
  position: relative;
  left: 14px;
}
a:active {
  color: #ffffff;
  text-decoration: none;
}

.services-block-three > a:hover:before,
.services-block-three > a:hover:after {
  width: 95%;
  height: 90%;
}

.portfolio-image {
  height: 300px;
  width: 100%;
}

.modal.show .modal-dialog {
  top: 25%;
}

.loader-spin {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Bottom - Top sites css  */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  /* transform: rotate(-90deg); */
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  display: inline-block;
}
.scroll-to-top:hover {
  background-color: #0056b3;
  transform: rotate(-180deg);
}
.notification-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.notification-badge {
  background-color: #118ce9;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-left: 10px;
}

.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.btn {
  margin-left: 5px;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-20px);
  }
  40% {
    transform: translateY(-10px);
  }
}
.admin-portal {
  position: fixed;
  top: 0;
  left: 120px;
  background-color: #16c1f7;
  padding: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.user-create-button {
  display: flex;
  align-items: center;
}

/* .table-container {
  max-height: calc(100vh - 150px); 
  overflow-y: auto;
  overflow-x: hidden;
} */
.table-div {
  margin-top: -10px;
}
.contact_wrapper {
  /* background-image: url(../images/cn-bg.jpg); */
  background-color: #fff;
  padding: 25px 10px;
  border-radius: 5px;
}
.contactDetails {
  color: #000;
}
.contactDetails table tbody tr th {
  size: 16px;
  color: #000;
}
/* .App {
  height: 100vh;
}  */
/* header .navbar .navbar-nav a.nav-link a :last-child {
padding-right: 0px;
} */
.service1 {
  width: 93%;
  padding-right:65px;
}
.Service2{
  padding-left:65px;
}
.phone_no {
  text-decoration: none;

  position: relative;
  font-size: 13px;
}

.testimonial_list .slick-track {
  display: flex;
  align-items: stretch;
}

.testimonial_list .slick-slide {
  height: auto;
  min-height: auto;
}

.testimonial_list .slick-slide div {
  height: auto;
  min-height: auto;
  margin-bottom: 15px;
}
.single_slide_content_text {
  /* min-width: 370px; */
  justify-content: center;
  display: flex;
  text-align: center;
  min-height: 330px;
  border-radius: 10px;
  margin: 20px;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.single_slide {
  width: 100%;
  display: inline-block;
  /* border: 1px solid black; */
  justify-content: center;
  display: flex;
}
.single_slide :hover{
  transform: scale(1.1);
}
.single_slide_content_text :hover{
  transform: scale(1.0);
}
.single_inbox_text img {
 width:auto;
 max-width:100px;
 height:80px;
  
  margin:0 auto 17px;
}
.single_inbox_text {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.slick-track {
  margin-bottom: 10px;
}
.single_inbox_text h3 {
  margin-bottom: 12px;
}
.slider_about {
  margin: 70px 0px 70px 0px;
}
.single_inbox_text p {
  margin-bottom: 0px;
}
.social_icon{
  justify-content: center;
  display:flex;
  padding-top: 16px;
  padding-bottom: 16px;
}.form_box {
  position: relative;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  pointer-events: none; /* Ensures the icon does not block input interactions */
}

.form_box input {
  padding-right: 30px; /* Adjust padding to fit the icon */
}

.content {
  text-transform: capitalize;
}
.formText{
  color:black ;
  font-size:medium;
}
.cardssssss .card{
  min-height: 320px;
}
.card .responsibilities {
  overflow: hidden;
}
.card {
  transition: height 0.3s ease;
  height:auto !important
}
.container.slider-container {

  max-width: 80%;
  margin: 0 auto;
}

.slick-slider {
  position: relative;
  overflow: hidden;
}

.slider-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.tech-Body {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 80px;
}
.cardssssss.card{
  min-height: 0px !important;
}
.cardssssss.card .card-body{
  padding:20px 25px 20px 25px !important
}



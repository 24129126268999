/* sideber css style */

/*======================== manhood WIDGET CSS =========================*/

/* sidebar css */

/* serch widget */

.blog-left-side {
}

.blog-left-side > div {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.blog-left-side > div:last-child {
  margin-bottom: 0px;
}

.blog-left-side h2 {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.blog-left-side {
}

.blog-left-side .content {
  padding: 0;
}

.blog-left-side .push-bottom-large {
  margin-bottom: 0;
}

.blog-left-side > div:last-child {
  margin-bottom: 0px;
}

.blog-left-side > aside:last-child {
  margin-bottom: 0px !important;
}

.blog-left-side .sub-menu li a:hover,
.blog-left-side .nav .children li a:hover {
  background: transparent;
  color: #0762f8;
}

.search {
  background: #f7f7f7;
  position: relative;
}

.search input {
  background: #fff none repeat scroll 0 0;
  border: 0 none;
  height: 54px;
  position: relative;
  width: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.search input[type="text"] {
}

.search input::-ms-input-placeholder,
.search input::-webkit-placeholder,
.search input::-moz-placeholder {
  color: #858585;
  font-size: 14px;
}

.search button {
  background: #fff;
  border: 0 none;
  color: #fff;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0;
  width: 16%;
  height: 100%;
  background: #0762f8;
}

.search button i {
}

.search input[type="text"] {
  padding-left: 10px;
}

/* widget list */

.widget {
}

.blog-left-side .widget h2 {
  bottom: -1px;
  color: #0d1218;
  display: block;
  font-size: 24px;
  line-height: 17px;
  margin-bottom: 20px;
  padding-bottom: 16px;
  text-transform: capitalize;
  font-weight: 700;
}

.footer-middle .widget h5 {
  color: #333;
  font-size: 24px;
  text-transform: capitalize;
}

.blog-left-side .widget ul {
  list-style: none;
  color: #0762f8;
}

.blog-left-side .widget ul li {
  margin: 0 0 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  position: relative;
}

.blog-left-side > .widget > ul > li {
  margin: 0 0 10px;
  padding: 8px 0 15px;
  border-bottom: 1px solid #ddd;
}

.blog-left-side .widget ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0 solid #ddd;
}

.blog-left-side .widget ul li a {
  color: #333;
}

.blog-left-side .widget ul li a i {
  margin-right: 10px;
  font-size: 12px;
}

.blog-left-side .widget ul li a span {
  float: right;
}

.blog-left-side .widget ul li::before {
  color: #333;
  content: "\f178";
  font-family: FontAwesome;
  font-size: 18px;
  left: 0;
  position: absolute;
  top: -3px;
  display: none;
}

.sbi_photo_wrap {
  float: left;
  margin: 2px;
}
.sbi_photo_wrap img {
  width: 88px;
  height: 88px;
}

.widget_recent_data .recent-post-text h4 {
  font-size: 18px;
}

.widget_recent_data .recent-post-text a {
  color: #232323;
  font-weight: 500;
  transition: 0.5s;
  font-weight: 700;
}

.textwidget {
  padding: 0 0 223px;
}

.widget_recent_data .recent-post-text a:hover {
  color: #118ce9;
}

/* widget select */

.widget {
  padding: 15px;
}

.blog-left-side .widget select {
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  padding-left: 5px;
}

.widget .screen-reader-text {
  display: none;
}

.textwidget select {
  width: 100%;
}

/* table css */

#wp-calendar {
  width: 100%;
}

.recent-post-item {
  margin-top: 25px;
}

.recent-post-image {
  float: left;
  margin-right: 3px;
}

.recent-post-text h4 {
  margin: 0;
}

/* tags */

.tagcloud a {
  color: #606060;
  display: inline-block;
  font-size: 14px;
  margin: 0 2px 10px 0;
  padding: 4px 15px;
  text-align: center;
  background: #f7fafd;
  border-radius: 5px;
}

.tagcloud a:hover {
  color: #fff !important;
  background: #0762f8;
}

.widget_categories ul li:hover:before,
.widget_archive ul li:hover:before,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.tagcloud a:hover,
.r-post-content h3:hover,
.r-post-content h3 a:hover {
  color: #0762f8;
}

#wp-calendar caption {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
  text-align: left;
  text-transform: uppercase;
}

#wp-calendar {
  font-size: 13px;
}

#wp-calendar {
  border: 0px solid #e5e5e5;
  margin-bottom: 20px;
  overflow: auto;
  vertical-align: top;
  width: 100%;
  margin: 0;
  color: #333333;
}

table#wp-calendar td {
  border: 1px solid #e5e5e5;
  color: #444444;
  padding: 5px 10px;
  text-align: center;
  vertical-align: top;
  font-size: 14px;
}

#wp-calendar th,
#wp-calendar td {
  padding: 8px;
}

table#wp-calendar th {
  border: 1px solid #e5e5e5;
  color: #333333;
  font-size: 13px;
  font-weight: 700;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}

table#wp-calendar td#today {
  color: #fff;
  background-color: #0762f8;
}
